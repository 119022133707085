/*
 v1.8.1605013974
 @preserve TRK_HOST        : 'axl.zenmara.fr'
 @preserve TRK_FIRST_PARTY : '1'
*/
(function(f,y){var p=f.console&&f.console.log?f.console:{log:function(){}};if(f.hasOwnProperty("KWKUniJS"))return p.log("KWKUniJS still loaded"),!1;var r=!1;var n="axl.zenmara.fr";var t="";var q="1";var u=function(){};u.prototype={encode:f.encodeURIComponent||f.escape,isDefined:function(a){return void 0!==a&&null!==a},isFunction:function(a){return"function"===typeof a},isString:function(a){return"string"===typeof a},isObject:function(a){return null!==a&&"object"===typeof a},isNumber:function(a){return"number"===
typeof a||!isNaN(a)&&"string"===typeof a&&0<a.length},isArray:function(a){return a.constructor===Array},printCurrentDate:function(){return(new Date).toLocaleString()},stringify:function(a){if(!this.isObject(a)||null===a)return"";if(this.isObject(f.JSON)&&this.isFunction(f.JSON.stringify))return f.JSON.stringify(a);var b=[];var d="";for(b in a){var c=a[b];this.isString(c)?c='"'+c+'"':this.isObject(c)&&null!==c&&(c=this.stringify(c));d+='"'+b+'":'+c}return"{"+d+"}"},fetchParamFromURI:function(a,b){b=
void 0===b?null:b;var d;b||(b=f.location.href);a=a.replace(/[\[\]]/g,"\\$&");return(d=(new RegExp("[?&]"+a+"(=([^&#?]*)|&|#|$)")).exec(b))?d[2]?decodeURIComponent(d[2].replace(/\+/g," ")):"":null},fetchProtocol:function(){var a;var b=f.location.protocol;if("https:"!==b){var d=/\/^(http:|https:)axl.zenmara.fr\/script.js$\//;var c=f.document.getElementsByTagName("script");for(a=0;a<c.length;a++)if(b=d.exec(c[a].src),this.isObject(b))return b[1]}return"https:"},sendRequest:function(a,b,d,c,h,l,k){try{var e=
f.XMLHttpRequest?new XMLHttpRequest:new ActiveXObject("Microsoft.XMLHTTP");"GET"===a.toUpperCase()&&0<d.length&&(b+="?"+d);e.open(a,b,!0);this.tools.isObject(c)&&Object.keys(c).map(function(g){e[g]=c[g]});e.onreadystatechange=function(){3<e.readyState&&(200===parseInt(e.status)||204===parseInt(e.status)?h(e):l(e))};"POST"===a.toUpperCase()&&e.setRequestHeader("Content-Type","application/x-www-form-urlencoded");e.setRequestHeader("X-Requested-With","XMLHttpRequest");e.setRequestHeader("X-KWKunijs-Version",
"v1.8.1605013974");k&&e.setRequestHeader("X-KWKunijs-Debug",1);e.send(d)}catch(g){return p.log(g),!1}return e},createPixel:function(a){a+=(a.split("?")[1]?"&":"?")+"kwkujs=1";var b=f.document.createElement("img");b.setAttribute("src",a);b.setAttribute("width","1px");b.setAttribute("height","1px");f.document.body.appendChild(b);this.debug('trkpix "'+a+'" appended to DOM')}};"function"!==typeof Array.prototype.push&&(Array.prototype.push=function(a){this[this.length]=a;return this.length});var v=function(){};
v.prototype={setCookie:function(a,b,d){d=void 0===d?60:d;isNaN(d)&&(d="30");if(!this.tools.isDefined(a)||!this.tools.isDefined(b)||isNaN(d))return!1;var c=new Date;c.setTime(c.getTime()+864E5*d);f.document.cookie=a+"="+b+"; expires="+c.toUTCString()+"; path=/";this.debug('store cookie : "'+a+"="+b+"; expires="+c.toUTCString()+'; path=/"');return!0},getCookie:function(a){var b=f.document.cookie.split(";");var d=a+"=";for(a=0;a<b.length;a++){var c=b[a];var h=c.indexOf(d);if(-1!==h)return c.substring(h+
d.length)}return null},isLSReady:function(){return"object"===typeof f.localStorage},setLSData:function(a,b,d){d=void 0===d?60:d;isNaN(d)&&(d="30");var c=(new Date).getTime();d=(new Date(c+864E5*d)).getTime();f.localStorage.setItem(a,b);f.localStorage.setItem(a+"t",d);this.debug("store "+a+"="+b+" and "+a+"t="+d+" in localstorage");return!0},getLSData:function(a){if(this.isLSReady())return[f.localStorage.getItem(a),parseInt(f.localStorage.getItem(a+"t"))]}};var w=function(){};w.prototype={tools:new u,
storage:new v,isEnvDebug:function(){"undefined"===typeof this.KWKDebug&&(this.KWKDebugLevel=parseInt(this.storage.getCookie("kwkdebug")),this.KWKDebug=0<Math.abs(this.KWKDebugLevel));return this.KWKDebug},debugDoAction:function(){return!this.isEnvDebug()||this.isEnvDebug()&&-1!==this.KWKDebugLevel},debug:function(a,b){this.isEnvDebug()&&(p.log("KWKDEBUG "+this.KWKDebugLevel+" - "+this.tools.printCurrentDate()+" : "+a),this.tools.isDefined(b)&&p.log(b))},buildTrkUrl:function(a){var b=this.tools.fetchProtocol();
a=this.tools.isDefined(a.event)&&0<a.event.length?"":t;return b+"//"+n+"/"+a},buildTrkMandatoryArgs:function(a){var b;var d="";var c=this.tools.isDefined(a.event)?["event"]:["mclic"];if(0<c.length){if(!this.tools.isArray(c))return this.debug("unable to build conv url, missing mandatory args"),!1;for(b=0;b<c.length;b++){if(!a.hasOwnProperty(c[b])||!this.tools.isDefined(a[c[b]]))return this.debug('unable to build conv url : mandatory arg "'+c[b]+'" is missing'),!1;d+=(""===d?"":"&")+(1==q&&"mclic"===
c[b]?"":c[b]+"=")+a[c[b]];delete a[c[b]]}}else for(b=0;b<a.length;b++)d+=(""===d?"":"&")+a[b]+"="+a[b];this.isEnvDebug()&&(d+=(""===d?"":"&")+"__nadebug=1");return d},buildTrkUnivParam:function(a){var b=this.getUniv();return this.tools.isDefined(b)&&0<b.length?(""===a?"":"&")+"kwkuniv="+b:""},buildPtagUrl:function(a,b){return this.tools.fetchProtocol()+"//axl.zenmara.fr/u/"+a%41+"/p"+a+(this.tools.isDefined(b)?b:"")+".js"},watch:function(){this.debug("start watching for kwkuniv");var a=this.tools.fetchParamFromURI("kwkuniv");
this.tools.isDefined(a)?(this.debug('kwkuniv catched with value "'+a+'" from "'+f.location.href+'"'),this.debugDoAction()&&(this.storage.setCookie.call(this,"kwku",a)&&this.debug("kwkuniv stored in cookie kwku"),this.storage.setLSData.call(this,"kwkuls",a)&&this.debug("kwkuniv stored in localstorage kwkuls"))):this.debug("no kwkuniv catched");this.tools.isDefined(f.kwkgtm)&&this.gtmCall(f.kwkgtm)},gtmCall:function(a){r=!0;this.debug("gtmCall with following params: ",a);q=this.tools.isDefined(a.trkdomain)&&
a.trkdomain!==n;n=this.tools.isDefined(a.trkdomain)?a.trkdomain:n;t=q?"":t;var b=this.tools.isDefined(a.trktype)?a.trktype:"conv";var d=this.tools.isDefined(a.progid)?a.progid:0;var c=this.tools.isDefined(a.mclic)&&0<a.mclic.length||"clicktime"===b&&0<d;var h=this.tools.isDefined(a.ptag)&&a.ptag;delete a.trkdomain;delete a.trktype;delete a.progid;c&&("dclick"===b?this.dclick(a):"clicktime"===b?this.clicktime(d):this.conversion(a));h&&0<d&&this.injectPtag([d])},doTrk:function(a){var b=1==q;"function"===
typeof f.__tcfapi?b?this.kwkCheckConsent(this,this.fetchKwkCookies,[a]):this.kwkCheckConsent(this,a,[]):(this.debug("UNDEFINED __tcfapi method : no consent check required !"),b?this.fetchKwkCookies(a):a())},conversion:function(a){var b={};Object.keys(a).map(function(e){"undefined"!==typeof a[e]&&0!=a[e].length&&(b[e.toLowerCase()]=a[e])});var d=r||this.tools.isDefined(b.altid)&&0<b.altid.length;var c=this.buildTrkUrl.call(this,a);var h=function(e){var g=this.buildTrkMandatoryArgs.call(this,e);if(!1===
g)return!1;Object.keys(e).map(function(m){this.tools.isArray(e[m])?e[m].forEach(function(x){g+=(""===g?"":"&")+m+"[]="+x}):g+=(""===g?"":"&")+m+"="+e[m]}.bind(this));r&&this.tools.isDefined(e.kwkuniv)||(g+=this.buildTrkUnivParam.call(this,g));return g}.call(this,b);if(!c||!h)return this.debug('FAILED to build conv url "'+c+'" and/or args "'+h+'"'),!1;var l=c+"?"+h;this.debug((this.debugDoAction()?"":"should trigger ")+'conversion call on "'+l+'"');var k=function(e){this.tools.isObject(e)&&0<Object.keys(e).length&&
e.constructor===Object&&Object.keys(e).forEach(function(g){h+=(""===h?"":"&")+g+"="+e[g];l+=(""===l?"":"&")+g+"="+e[g]});this.debugDoAction()&&(d?(this.tools.createPixel.call(this,l),this.debug("trk conversion forced with pixel")):this.tools.sendRequest.call(this,"POST",c,h+(0<h.length?"&":"")+"kwkujs=2",{withCredentials:!0},function(g){g.status&&200===parseInt(g.status)?this.debug("trk conversion call returned OK"):g.status&&204===parseInt(g.status)?this.debug("trk conversion call returned NOK"):
(this.debug("unexpected return status for trk conversion call using xhr",g),this.tools.createPixel.call(this,l),this.debug("trk conversion with pixel fallback"))}.bind(this),function(g){this.debug("trk conversion call failed using xhr",g);this.tools.createPixel.call(this,l);this.debug("trk conversion with pixel fallback")}.bind(this),this.isEnvDebug()))}.bind(this);this.doTrk(k);return!0},clicktime:function(a){var b=this.tools.fetchProtocol()+"//axl.zenmara.fr/na/na/res/trk/script-ct.js";if(this.debugDoAction()){var d=
function(c){c=f.document.createElement("script");c.setAttribute("src",b);c.setAttribute("type","text/javascript");c.onload=function(){new __trknanact(a);this.debug("ctime call fired for p"+a)}.bind(this);f.document.getElementsByTagName("body")[0].appendChild(c)}.bind(this);this.doTrk(d);return!0}this.debug('SHOULD append ctime url "'+b+'" to DOM for p'+a+' and trigger call "__trknanact('+a+')"')},dclick:function(a){var b={};Object.keys(a).map(function(k){"undefined"!==typeof a[k]&&0!=a[k].length&&
(b[k.toLowerCase()]=a[k])});var d=this.buildTrkUrl.call(this,b);var c=this.buildTrkMandatoryArgs.call(this,b);if(!1===c)return!1;c+=this.buildTrkUnivParam.call(this,c);if(!d||!c)return this.debug('FAILED to build dclick url "'+d+'" and/or dclick args "'+c+'"'),!1;d+="?"+c;var h=d+"?"+c;var l=function(k){this.tools.isObject(k)&&0<Object.keys(k).length&&k.constructor===Object&&Object.keys(k).forEach(function(e){c+=(""===c?"":"&")+e+"="+k[e];h+=(""===h?"":"&")+e+"="+k[e]});this.tools.isDefined(b.redir)&&
0<b.redir.length?(this.debug('Dclick asked with redir to "'+b.redir+'"'),c+="&redir="+encodeURIComponent(b.redir),h+="&redir="+encodeURIComponent(b.redir),this.debug('Final redir url is "'+h+'"'),this.debugDoAction()?f.location.replace(h):this.debug('SHOULD fire dclick redirection to trk url "'+h+'"')):this.debugDoAction()?this.tools.sendRequest.call(this,"POST",d,c+(0<c.length?"&":"")+"kwkujs=2",{withCredentials:!0},function(e){e.status&&200===parseInt(e.status)?this.debug("trk dclick call returned OK"):
e.status&&204===parseInt(e.status)?this.debug("trk dclick call returned NOK"):(this.debug("unexpected return status for dclick call using xhr",e),this.tools.createPixel.call(this,h),this.debug("trk dclick with pixel fallback"))}.bind(this),function(e){this.debug("trk dclick call failed using xhr",e);this.tools.createPixel.call(this,h);this.debug("trk dclick with pixel fallback")}.bind(this),this.isEnvDebug()):this.debug('SHOULD fire dclick url "'+h+'" or add it as pixel in the current page.')}.bind(this);
this.doTrk(l);return!0},ptag:function(a){this.tools.isDefined(a)&&this.tools.isDefined(Object.keys(a))&&0<Object.keys(a).length&&(this.debug("set following args in window.ptag_params :",a),f.ptag_params=a);var b=function(d){d=this.tools.isString(d)?JSON.parse(d):d;this.tools.isArray(d)&&0!=d.length?this.debug(d.length+" progs loaded from setup : "+d):(d=["70563"],this.debug("init prog list from default prog 70563"));this.injectPtag.call(this,d)}.bind(this);fetch(this.buildPtagUrl.call(this,"70563",
"u")).then(function(d){return d.json()}).then(function(d){b(d)});return!0},injectPtag:function(a){var b;for(b=0;b<a.length;b++){var d=this.buildPtagUrl.call(this,a[b]);if(!this.tools.isDefined(d)||0===d.length){this.debug("Unable to build PTag url for p"+a[b]);break}if(this.debugDoAction()){var c=f.document.createElement("script");c.src=d;f.document.body.appendChild(c);this.debug('Ptag url "'+d+'" appended to DOM')}else this.debug('SHOULD append Ptag url "'+d+'" to current page.')}},fetchKwkCookies:function(a,
b){var d=function(c,h,l){var k=new Date;Object.keys(c).forEach(function(e){var g=void 0!==c[e][0]&&null!==c[e][0]?c[e][0]:"";var m=void 0!==c[e][1]&&null!==c[e][1]?parseInt(c[e][1]):"30";k.setTime(k.getTime()+864E5*m);f.document.cookie=e+"="+g+";expires="+k.toGMTString()+";path=/;domain="+n.substring(n.indexOf(".",0))});h.call(this,l)};this.tools.sendRequest.call(this,"POST",this.tools.fetchProtocol()+"//action.metaffiliation.com/kwkc.php","",{withCredentials:!0},function(c){c.status&&200===parseInt(c.status)?
(c=JSON.parse(c.response),this.tools.isObject(c)&&(d.call(this,c,a,b),this.debug("kwk cookies successfully fetched"))):(this.debug("xhr call returned error status code",c),a.call(this,b))}.bind(this),function(c){this.debug("unable to fetch trk cookie using xhr",c);a.call(this,b)}.bind(this),this.isEnvDebug());return!0},kwkCheckConsent:function(a,b,d){this.trkContext=a;this.trkCallback=b;this.trkCallbackArgs=d;a=this.kwkConsentCallback.bind(this);__tcfapi("addEventListener",2,a)},kwkConsentCallback:function(a,
b){if(!b||"tcloaded"!==a.eventStatus&&"useractioncomplete"!==a.eventStatus)b||this.trkCallback.apply(this.trkContext,this.trkCallbackArgs);else{var d={gdpr:"undefined"===typeof a.gdprApplies?"2":a.gdprApplies?"1":"0",gdpr_kwk:"undefined"===typeof a.vendor.consents[702]?"2":a.vendor.consents[702]?"1":"0",gdpr_consent:"string"===typeof a.tcString&&0<a.tcString.length?"iabtcfv2_"+a.tcString:""};this.trkCallbackArgs.push(d);this.trkCallback.apply(this.trkContext,this.trkCallbackArgs);__tcfapi("removeEventListener",
2,function(c){},a.listenerId)}},getUniv:function(){var a=this.storage.getCookie("kwku");if(!this.tools.isDefined(a)||0>=a.length)a=this.storage.getLSData("kwkulskwkuniv")[0];return a}};f.KWKUniJS=new w;f.KWKUniJS.watch()})(window,document);
